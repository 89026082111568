import { SUPPORTED_FILE_MIME_EXTENSION_TYPES } from 'api/src/common/constants'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'

export const getAcceptedTypesForInput = (
  allowedTypes: SUPPORTED_FILE_MIME_TYPES[],
): string => {
  return allowedTypes
    .map((type) =>
      SUPPORTED_FILE_MIME_EXTENSION_TYPES[type]
        .map((ext) => type + '/' + ext)
        .join(', '),
    )
    .join(', ')
}

export const CREATE_STORAGE_OBJECT = gql`
  mutation CreateStorageObjectMutation($input: CreateStorageObjectInput!) {
    createStorageObject(input: $input) {
      id
      uploadUrl
    }
  }
`

export const UPDATE_STORAGE_OBJECT = gql`
  mutation UpdateStorageObjectMutation(
    $id: Int!
    $input: UpdateStorageObjectInput!
  ) {
    updateStorageObject(id: $id, input: $input) {
      id
      downloadUrl
    }
  }
`
